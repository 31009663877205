import React from 'react';
// import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskDurationInput } from '../../../Job/TaskDurationInput';
import { getPredecessorsString } from '../TemplateDetailsContext';
import { TableRowDropDown } from '@tradetrax/web-common';
import { SubTasksList, ItemContainer, Divider, TaskNameContainer } from '@tradetrax/web-common/lib/Stages';
import { DraggableArea } from '@tradetrax/web-common/lib/Stages/DragNDrop/DraggableArea';

export const InnerTasksList = ({
  rowData,
  rowIndex,
  controller,
  dndController,
  expandedRows,
  getCellHeight,
  width,
}) => {
  const tasks = rowData.get('tasks');
  const containSubTasks = task => task.get('children').size > 0;
  const onClickExpand = task => {
    controller.toggleRow('stages', task, rowIndex);
  };

  return (
    <>
      {tasks.map((task, index) => {
        const canExpand = containSubTasks(task);
        const isExpanded = !!expandedRows.get(String(task.get('id')));
        const icon = isExpanded ? 'chevron-up' : 'chevron-down';
        const height = getCellHeight(task);
        return (
          <ItemContainer
            key={`task-${index}`}
            className="pl-4"
            height={height}
            data-task-order={task.get('order')}
            data-stage-id={task.get('stageId')}
          >
            <Divider />
            <div className="d-flex flex-row align-items-center">
              <DraggableArea
                controller={dndController}
                task={task}
                className={cn('drag-icon font-size-14 mr-4', { 'pr-4': !canExpand })}
              />
              {canExpand && <FontAwesomeIcon icon={icon} className="mr-2" onClick={() => onClickExpand(task)} />}
              <TaskNameContainer
                text={task.get('name')}
                width={width - 100}
                isKeyFinish={task.get('isKeyFinish')}
                isKeyStart={task.get('isKeyStart')}
                isInner
              />
            </div>
            {isExpanded && (
              <SubTasksList
                className="ml-5 pl-3"
                subTasks={task.get('children')}
                rowData={task}
                controller={controller}
                isTemplate
              />
            )}
          </ItemContainer>
        );
      })}
      <button onClick={() => controller.openAddTaskModal(rowData, rowIndex)} className="btn btn-link font-size-12 pl-4">
        + Add Task
      </button>
    </>
  );
};

export const InnerDurationList = ({ tasks, getCellHeight, controller }) => {
  const [editingRow, setEditingRow] = React.useState(null);

  React.useEffect(() => {
    if (editingRow !== null) {
      const onClick = e => {
        const { target } = e;
        if (target.tagName === 'INPUT') return;
        setEditingRow(null);
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [setEditingRow, editingRow]);

  return (
    <>
      {tasks.map((item, index) => {
        const task = item.set('durationDays', item.get('duration'));
        const height = getCellHeight(task, index);
        return (
          <ItemContainer key={`duration-${index}`} height={height}>
            {editingRow === index && <TaskDurationInput controller={controller} task={task} />}
            {editingRow !== index && (
              <span onClick={() => setEditingRow(index)} className="d-block pr-4 editable">
                {task.get('durationDays')}d
              </span>
            )}
          </ItemContainer>
        );
      })}
    </>
  );
};

export const InnerPredecessorsList = ({ tasks, template, getCellHeight, onClickDependencies }) => {
  return (
    <>
      {tasks.map((task, index) => {
        const height = getCellHeight(task, index);
        const predecessorsString = getPredecessorsString(task.get('predecessors'), template.get('tasks'));
        return (
          <ItemContainer key={`predecessor-${index}`} height={height}>
            <div className="editable" onClick={() => onClickDependencies(task)}>
              {predecessorsString && (
                <>
                  {predecessorsString} <FontAwesomeIcon icon="pen" />
                </>
              )}
              {!predecessorsString && <FontAwesomeIcon icon="pen-to-square" />}
            </div>
            {task?.get('missingReference') && (
              <div className="text-danger" style={{ marginLeft: '-10px' }}>
                Missing
              </div>
            )}
          </ItemContainer>
        );
      })}
    </>
  );
};

export const InnerDropDownActions = ({ tasks, getCellHeight, onSelect }) => (
  <>
    {tasks.map((task, index) => {
      const height = getCellHeight(task, index);
      return (
        <ItemContainer key={`actions-${index}`} height={height} pt="1.4rem">
          <TableRowDropDown remove onSelect={() => onSelect(task)} />
        </ItemContainer>
      );
    })}
  </>
);
