import React, { useEffect } from 'react';
import { fromJS } from 'immutable';
import { useController, DialogController, NotificationsController } from '@tradetrax/web-common';
import { AppContext, useAppContext } from '@tradetrax/web-common/lib/globalAppContext';
import { useEvent } from '@tradetrax/web-common/lib/useEvents';
import { usersService, buildersService } from '../services';
import { history } from './navigate';
import * as appActions from './App.actions';
import JobsStore from './stores/jobs';
import { invalidateCounters, getTaskCounterActions } from '@tradetrax/web-common/lib/TaskCounters/TaskCounters.actions';
import { useSocket } from '@tradetrax/web-common/lib/useRealTime';

export { AppContext, useAppContext };

const actions = {
  ...appActions,
  ...getTaskCounterActions(buildersService),
};

const defaultOptions = {
  expanded: true,
  notifications: false,
};

export const emptyState = fromJS({
  account: {
    name: '',
    email: '',
    trades: [],
    workingDays: {},
    holidays: [],
    subs: [],
    installers: [],
    users: [],
    usersActive: [],
    tradesLocationSettings: [],
    legalAgreements: {},
  },
  counters: {
    overdueCount: -1,
    requestCount: -1,
    toDoEndCount: -1,
    toDoStartCount: -1,
    toDoUpdateCount: -1,
  },
  communities: [],
  trades: [],
  gtl: [],
  templates: [],
  cookieBannerAccepted: true,
  globalSearchResults: [],
  filteredResults: [],
})
  .set('companies', [])
  .set('options', defaultOptions);

export function Context(defaultUser) {
  const [user, initialState] = React.useMemo(() => {
    const user = fromJS(defaultUser);
    return [user, emptyState.set('user', user)];
  }, [defaultUser]);
  const modal = DialogController();
  const miniAlertsRef = React.useRef();
  const alert = miniAlertsRef.current;
  const [appState, appController] = useController(actions, initialState, {
    modal,
    alert,
    miniAlertsRef,
    dashboardService: buildersService,
    isBuilder: true,
    invalidateCounters,
  });
  const notifications = NotificationsController(buildersService, usersService, user, alert);
  const [, notificationsController] = notifications;
  const areCookiesAccepted = appState.get('cookieBannerAccepted');
  const account = appState.get('account');

  useSocket(defaultUser);

  //TODO: migrate this to `useController` approach
  const jobsStore = JobsStore(React.useState(JobsStore.emptyState));
  const hasPermission = (key, value = 'yes') => appState.getIn(['user', 'permissions', key]) === value;
  const getPermission = key => appState.getIn(['user', 'permissions', key]);

  useEvent(
    'notifications:save-token',
    React.useCallback(
      token =>
        appController.dispatch([
          state => state.updateIn(['user', 'tokenDevices'], tokenDevices => fromJS([...tokenDevices, token])),
        ]),
      [appController]
    )
  );

  useEffect(() => {
    initialize(notificationsController);
    // TODO: load this resources on demand.
    appController.readAccount();
    appController.readCommunities();
    appController.readGTL();
    appController.getCookies();
    appController.readTaskCounters();
    setInterval(() => {
      appController.readTaskCounters();
    }, 120000); //120 secs
  }, [appController, notificationsController]);

  const { acceptCookies } = appController;
  useEffect(() => {
    if (!areCookiesAccepted) miniAlertsRef.current.showAcceptCookiesBanner(acceptCookies);
  }, [areCookiesAccepted, miniAlertsRef, acceptCookies]);

  const { legalAgreements } = account.toObject();
  useEffect(() => {
    if (legalAgreements?.size) {
      const isAcceptLegalAgreements = !Object.keys(legalAgreements.toJS()).some(agreement => {
        return agreement && !legalAgreements.get(agreement);
      });
      if (!isAcceptLegalAgreements) appController.openLegalAgreementModal();
    }
  }, [legalAgreements, appController]);

  // Commented while waiting for improved tech approach
  // const refreshCounters = React.useMemo(() => foo => appController.readTaskCounters(), [appController]);
  // useChannel(CHANNELS.ACCOUNT_TASK, defaultUser.accountId, refreshCounters);
  if (global.Cypress) {
    global.isAlertReady = !!alert;
  }

  return {
    modal,
    alert,
    notifications,
    appState,
    appController,
    jobsStore,
    account,
    errorMessage: errorMessage(miniAlertsRef),
    hasPermission,
    getPermission,
    addAlert: addAlert(miniAlertsRef),
    miniAlertsRef,
    invalidateCounters: counters => invalidateCounters.call({ controller: appController }, counters),
  };
}

function initialize(notificationsController) {
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', event => {
      console.log('app controller message => ', event.data, event.data.url);
      if (event.data.type === 'notification') {
        notificationsController.addMessage(event.data);
        history.push(event.data.url);
      }
      // else => it's a direct notification coming from FCN and will be handled on notifications controller.
    });
  }
}

function addAlert(alertRef) {
  return function(message, variant = 'success', defer = 100) {
    setTimeout(() => {
      const alert = alertRef.current;
      if (alert) {
        alert.add({ message, variant });
      }
    }, defer);
  };
}

// this is intended to be used on Promise.catch
// .catch(errorMessage('rat'))
function errorMessage(alertRef) {
  return function(message) {
    return () => {
      const alert = alertRef.current;
      if (alert) {
        alert.add({ message, variant: 'danger' });
      }
      return state => state;
    };
  };
}
