import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { PrimaryButton, colors, Typeahead as TrxTypeahead } from '@tradetrax/web-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller as FormController } from 'react-hook-form';
import { useAppContext } from 'app/App.context';
import { TASK_TYPE } from './OuttakeController';
import cn from 'classnames';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { List, fromJS } from 'immutable';

const schema = yup.object().shape({
  assignees: yup
    .array()
    .of(yup.object())
    .when('isValidationEnabled', {
      is: true,
      then: yup
        .array()
        .of(yup.object())
        .required('Assignee is required'),
    }),
  allJobs: yup.boolean(),
  isValidationEnabled: yup.boolean(),
  jobs: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
      })
    )
    .when('allJobs', {
      is: false,
      then: yup.array().min(1, 'Define which Jobs to assign.'),
    }),
});

const jobStatusClassMap = {
  'in-progress': 'text-green-300',
  completed: 'text-green-400',
  'not-started': 'text-muted',
};

export function TaskAssignmentModal({
  accept,
  cancel,
  community,
  tab,
  filter,
  jobsPromise,
  hasExistingRule,
  taskAssignees,
  isAnyNotAssigned,
}) {
  const { appState } = useAppContext();
  const { companies, account } = appState.toObject();
  const { register, control, errors, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: { assignee: '', jobs: [], rule: 'no' },
  });
  const [jobs, setJobs] = React.useState(List());
  const isTasksByType = filter === TASK_TYPE;

  React.useEffect(() => {
    jobsPromise.then(fromJS).then(jobs => setJobs(jobs));
  }, [jobsPromise, setJobs]);

  const [isAllJobsSelected, setAllJobs] = React.useState(false);
  const [isRuleOverride, setRuleOverride] = React.useState(true);
  const [isShowRule, setShowRule] = React.useState(false);
  const [isUnassign, setIsUnassign] = React.useState(false);
  const isValidationEnabled = !isUnassign && !taskAssignees.length;
  const watchRule = watch('rule') === 'yes';
  const isCreatingRule = watchRule && isShowRule;
  const loggedAccountId = account.get('_id');
  const isAccountAssignee = tab === 'account';
  const assignmentType = `${tab.charAt(0).toUpperCase()}${tab.slice(1)}`;
  const isCancelAssignment = taskAssignees.length && !isUnassign;
  const watchAssignees = watch('assignees');
  const defaultSelected = React.useMemo(
    () => getDefaultSelected(isTasksByType, taskAssignees, isAccountAssignee, companies, account),
    [isTasksByType, taskAssignees, isAccountAssignee, companies, account]
  );

  React.useEffect(() => {
    !watchRule && setRuleOverride(true);
  }, [watchRule]);

  React.useEffect(() => {
    const isAssigneeSelected = watchAssignees && !watchAssignees.length;
    const isUnassign = !isTasksByType && isAssigneeSelected && taskAssignees.length > 0;
    setIsUnassign(isUnassign);
  }, [isTasksByType, taskAssignees, watchAssignees]);

  const formatJobStatus = job =>
    job
      .get('status')
      .replace('-', ' ')
      .toUpperCase();
  const onSubmitForm = form => {
    const { assignees, jobs, rule } = form;
    const jobIds = isAllJobsSelected ? [] : jobs && jobs.map(job => job._id);
    const assignee =
      !isUnassign && !assignees && isAnyNotAssigned && taskAssignees.length === 1
        ? defaultSelected
        : assignees && assignees[0];
    if (isCancelAssignment && !assignee) cancel();
    else accept({ assignee, jobIds, createRule: rule === 'yes' });
  };

  return (
    <Modal show={true} onHide={cancel} dialogClassName={cn({ 'mw-600': isTasksByType })}>
      <Form noValidate onSubmit={handleSubmit(onSubmitForm)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Assign {assignmentType}
            <br />
            <h6>{community.get('name')}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="d-flex flex-column">
            {isTasksByType && <span className="font-weight-bold font-size-16 mb-2">{assignmentType}</span>}
            <div className="d-flex flex-row justify-content-between">
              <span className="form-label">{`Assign to ${assignmentType}`}</span>
            </div>
            <Form.Group controlId="assignees" className="mb-3">
              <FormController
                name="assignees"
                control={control}
                render={({ value, onChange }) => (
                  <>
                    {isAccountAssignee && (
                      <>
                        <AccountTypeahead
                          defaultSelected={defaultSelected}
                          isInvalid={!!errors.assignees}
                          loggedAccountId={loggedAccountId}
                          onChange={onChange}
                          options={companies}
                          placeholder="Choose Account"
                          value={value}
                        />
                      </>
                    )}
                    {!isAccountAssignee && (
                      <UsersTypeahead
                        defaultSelected={defaultSelected}
                        isInvalid={!!errors.assignees}
                        loggedUserId={appState.getIn(['user', '_id'])}
                        onChange={onChange}
                        options={account.get('usersActive')}
                        placeholder={`Choose ${assignmentType}`}
                        value={value}
                      />
                    )}
                  </>
                )}
              />
              {isTasksByType && !errors.assignees && (
                <span className="text-muted">
                  {`* Tasks already assigned will be reassigned to the selected ${assignmentType}`}
                </span>
              )}
              <Form.Control.Feedback type="invalid" className={cn({ 'd-block': !!errors.assignees })}>
                <FontAwesomeIcon icon="circle-exclamation" /> {errors.assignees?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <input checked={isValidationEnabled} name="isValidationEnabled" hidden ref={register} type="checkbox" />
          </div>
          {isTasksByType && (
            <div className="d-flex flex-column mb-3">
              <span className="font-weight-bold font-size-16 mb-2">Jobs</span>
              <Form.Check
                className="mr-1 ml-2"
                id="allJobsCheckbox"
                isInvalid={!!errors.jobs && !watch('allJobs')}
                name="allJobs"
                label="Select all existing Jobs"
                onClick={({ target }) => setAllJobs(target.checked)}
                ref={register}
              />
              <Form.Group controlId="account" className="my-2">
                <Form.Label>Select Specific Jobs</Form.Label>
                <FormController
                  control={control}
                  name="jobs"
                  isInvalid={!!errors.jobs}
                  render={props => (
                    <JobsTypeahead
                      {...props}
                      formatJobStatus={formatJobStatus}
                      isDisabled={isAllJobsSelected}
                      isInvalid={!!errors.jobs}
                      jobs={jobs}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group controlId="rule" className="my-2">
                <span className="font-weight-bold font-size-16">Create a Rule for Future Jobs?</span> (optional)
                <FontAwesomeIcon
                  className="text-muted font-size-12 ml-2 cursor-pointer"
                  icon={isShowRule ? 'chevron-up' : 'chevron-down'}
                  onClick={() => setShowRule(!isShowRule)}
                />
                <div className={cn({ 'd-none': !isShowRule })}>
                  <p className="mt-2">
                    By selecting "Create Rule to Apply to Future Jobs" the selected Task Type(s) will be assigned
                    automatically in the future. TradeTrax will remember this setting and will do it for you.{' '}
                  </p>
                  <div className="d-flex flex-row justify-content-around font-size-14">
                    <div className="d-flex align-items-center">
                      <input
                        className="mr-1"
                        defaultChecked
                        id="rule-no"
                        name="rule"
                        type="radio"
                        value="no"
                        ref={register}
                      />
                      <label className="mb-0">No, thanks. I will do it</label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input className="mr-1" id="rule-yes" name="rule" type="radio" value="yes" ref={register} />
                      <label className="mb-0">Yes, create Rule to Apply to Future Jobs</label>
                    </div>
                  </div>
                </div>
              </Form.Group>
              {hasExistingRule && isCreatingRule && (
                <Form.Group controlId="existing-rule" className="my-2">
                  <span className="font-weight-bold font-size-16">
                    <FontAwesomeIcon icon="circle-exclamation" className="mr-2 text-danger" />
                    Existing Rule
                  </span>
                  <p className="mt-2 text-muted">
                    A Rule already exists for one or more Task Type selected. Please remove the Rule first from the
                    rules section before creating a new Rule.
                  </p>
                  <Form.Check
                    id="rule-override"
                    defaultChecked
                    label="Delete existing Rules and create a new one."
                    name="override-rule"
                    onClick={({ target }) => setRuleOverride(target.checked)}
                    type="checkbox"
                  />
                </Form.Group>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={() => cancel()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton className="mr-n2" disabled={!isRuleOverride} type="submit">
            {isUnassign ? 'Unassign' : 'Assign'}
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const StyledMenuItem = styled(MenuItem)`
  height: 60px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
  a.dropdown-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
`;

const JobsTypeahead = ({ onChange, value, jobs, formatJobStatus, isDisabled, isInvalid }) => {
  const renderMenu = (options, menuProps) => (
    <Menu {...menuProps} maxHeight="310px">
      {options.map(option => {
        const index = jobs.findIndex(job => job.get('_id') === option._id);
        return (
          <StyledMenuItem key={index} option={option} position={index}>
            <span style={{ width: '70%' }} className="text-truncate">
              {option.name}
            </span>
            <span className={cn(`font-weight-bold ${jobStatusClassMap[jobs.get(index).get('status')]}`)}>
              {formatJobStatus(jobs.get(index))}
            </span>
          </StyledMenuItem>
        );
      })}
    </Menu>
  );

  if (isDisabled) return <Form.Control type="text" placeholder="All existing Jobs" disabled />;

  return (
    <>
      <Typeahead
        bsSize="lg"
        isInvalid={isInvalid}
        disabled={isDisabled}
        id="jobs-select"
        labelKey={option => option.name}
        multiple
        onChange={onChange}
        options={jobs.toJS()}
        placeholder="Choose Jobs"
        renderMenu={renderMenu}
        selected={value}
      >
        {!isInvalid && <FontAwesomeIcon icon="chevron-down" className="mx-1" />}
      </Typeahead>

      <Form.Control.Feedback type="invalid" className={cn({ 'd-block': isInvalid })}>
        <FontAwesomeIcon icon="circle-exclamation" /> Define which Jobs to assign.
      </Form.Control.Feedback>
    </>
  );
};

const AccountTypeahead = props => {
  const { loggedAccountId, defaultSelected } = props;
  const selected = defaultSelected ? [fromJS(defaultSelected)] : null;
  return (
    <TrxTypeahead
      {...props}
      id="assignee-account"
      selected={props.value || selected}
      labelKey={option => option.get('name')}
      isMyId={option => loggedAccountId === option.get('subAccountId')}
      filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
    />
  );
};

const UsersTypeahead = props => {
  const { options, defaultSelected, loggedUserId } = props;
  const selected = defaultSelected ? [fromJS(defaultSelected)] : null;
  return (
    <TrxTypeahead
      {...props}
      id="assignee-user"
      selected={props.value || selected}
      options={options.toArray()}
      labelKey={option => `${option.get('firstName')} ${option.get('lastName')}`}
      isMyId={option => loggedUserId === option.get('_id')}
      filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
    />
  );
};

function getDefaultSelected(isTasksByType, taskAssignees, isAccountAssignee, companies, account) {
  if (isTasksByType) return;
  if (taskAssignees.length > 1) {
    const severalAssigned = isAccountAssignee
      ? { name: 'Several Assigned' }
      : { firstName: 'Several', lastName: 'Assigned' };
    return severalAssigned;
  }
  if (isAccountAssignee) return companies.filter(company => taskAssignees[0] === company.get('subAccountId'))[0];
  return account
    .get('usersActive')
    .filter(user => taskAssignees[0] === user.get('_id'))
    .get(0);
}
