import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableRowDropDown,
  STATUS_MAP,
  ChipStatusDropdown,
  OnTime,
  EMPTY_ROW,
  renderCell,
  Typeahead,
} from '@tradetrax/web-common';
import {
  SubTaskStatusList,
  SubTasksList,
  TaskNameContainer,
  getRowHeightListView,
} from '@tradetrax/web-common/lib/Stages';
import {
  mongoToTrx,
  isoToTrxFull,
  datepickerFormat,
  isTaskOverdue,
  getAssigneeBadgeClassName,
  getMinEndDate,
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  handleTableScroll,
  scrollbarStyles,
} from '@tradetrax/web-common/lib/utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { TaskDurationInput } from '../TaskDurationInput';
import TaskDependencies from '../TaskDependencies';
import { DatepickerWithPortal } from './DatepickerWithPortal';
import { noRowsRenderer } from './JobDetails';
import { AssigneeBadge } from './JobStages';
import navigate from 'app/navigate';
import { TrxTooltip as Tooltip } from '@tradetrax/web-common/lib/Tooltip';

const COL_WIDTHS = {
  span: 32,
  rowNo: 16,
  duration: 5 * 16,
  confirmed: 8 * 16,
  expStart: 8 * 16,
  expFinish: 8 * 16,
  dep: 3 * 16,
  status: 160,
  action: 32,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const ScheduleListView = ({ jobContext, isFilterSet }) => {
  const [editingRow, setEditingRow] = useState(null);
  const [defaultEditField, setDefaultEditField] = useState(null);
  const { state, controller, account, modal, companies, schedulerRef, hasPermission, loaderRef } = jobContext;
  const { job, filteredTasks: allTasks, isLoading } = state.toObject();
  const tasks = allTasks.filter(task => !task.get('isStageTask'));
  const totalCount = tasks.size;
  const canEditDatesAndPredecessors = hasPermission('job_update_task_dependencies_and_dates');
  const expandedRows = state.getIn(['expandedRows', 'list']);
  const showDaysBehind = false;

  const changeDependencies = task => modal.open(TaskDependencies, { controller, task, tasks, job });
  const calendarRef = useRef();
  const scrollDivRef = useRef(null);
  useEffect(() => {
    const calendarPortal = document.createElement('div');
    document.body.appendChild(calendarPortal);
    calendarPortal.id = 'calendar-portal';
    calendarPortal.ref = calendarRef;
    return () => {
      document.body.removeChild(calendarPortal);
    };
  }, [calendarRef]);

  useEffect(() => {
    if (editingRow !== null) {
      const onClick = e => {
        const { target } = e;
        if (target.tagName === 'INPUT') return;

        let element = target;
        while (element) {
          const { classList } = element;
          if (classList.contains('react-datepicker__navigation')) return;
          if (classList.contains('react-datepicker')) return;
          if (classList.contains('rbt-menu')) return;
          element = element.parentElement;
        }
        setEditingRow(null);
        setDefaultEditField(null);
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [setEditingRow, setDefaultEditField, editingRow]);

  const cancelEdit = () => {
    setEditingRow(null);
    setDefaultEditField(null);
  };

  const onEditFieldClick = (index, field) => e => {
    e.preventDefault();
    e.stopPropagation();
    setEditingRow(index);
    setDefaultEditField(field);
  };

  const onToggleRow = (task, index) => controller.toggleRow('list', task, index);

  const getRowHeight = ({ index }) => getRowHeightListView(index, tasks, expandedRows);

  useEffect(() => {
    if (editingRow === null || defaultEditField === null) return;

    const [row] = window.document.getElementsByClassName('editing-row');
    if (!row) return;

    let [element] = row.getElementsByClassName(`edit-${defaultEditField}`);
    if (!element) return;

    if (element.tagName !== 'INPUT') {
      element = element.getElementsByTagName('input')[0];
    }

    if (element && element.tagName === 'INPUT') {
      element.focus();
    }
  }, [editingRow, defaultEditField]);

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) {
      element.addEventListener('scroll', handleTableScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleTableScroll);
      }
    };
  }, []);

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={() => {}}
        rowCount={isLoading ? 7 : totalCount}
        ref={loaderRef}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <ScrollDiv ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1250 ? width : 1250;
                return (
                  <Table
                    ref={ref => {
                      registerChild(ref);
                      schedulerRef.current = ref;
                    }}
                    onRowsRendered={onRowsRendered}
                    className="trx-table"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    onRowDoubleClick={({ index }) => setEditingRow(index)}
                    onRowClick={({ index }) => index !== editingRow && cancelEdit()}
                    overscanRowCount={2}
                    rowHeight={getRowHeight}
                    estimatedRowSize={80}
                    rowCount={isLoading ? 7 : totalCount}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    noRowsRenderer={noRowsRenderer(isFilterSet, isLoading)}
                    rowClassName={({ index }) =>
                      cn({ loading: !tasks.get(index), 'editing-row': index === editingRow })
                    }
                    onScroll={event => handleTableScroll(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label=""
                      dataKey="rowIndex"
                      width={COL_WIDTHS.rowNo}
                      className="h-100 mt-4 pt-3"
                      cellRenderer={renderCell(({ rowData }) => {
                        const rowIndex = tasks.find(task => task.get('id') === rowData.get('id')).get('rowIndex');
                        return <>{rowIndex}</>;
                      })}
                    />
                    <Column
                      label="Task Name"
                      dataKey="name"
                      className="h-100  pt-3"
                      minWidth={280}
                      width={(minWidth - WIDTHS) / 2}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const subTasks = rowData.get('children');
                        const canExpand = subTasks.size > 0;
                        const isExpanded = !!expandedRows.get(String(rowData.get('id')));
                        const icon = isExpanded ? 'chevron-up' : 'chevron-down';
                        const isCritical = rowData.get('isCritical');
                        return (
                          <>
                            <span className={cn('d-flex flex-row', { 'ml-3 pl-1': !canExpand })}>
                              {canExpand && (
                                <span className="arrowContainer">
                                  <FontAwesomeIcon
                                    icon={icon}
                                    className="mr-1 "
                                    onClick={() => onToggleRow(rowData, rowIndex)}
                                  />
                                </span>
                              )}
                              <div className="d-flex">
                                <Link
                                  className="main-link"
                                  to={{
                                    pathname: navigate.from.JobDetails.to.JobTaskDetails(
                                      { taskId: tasks.getIn([rowIndex, 'id']) },
                                      false
                                    ),
                                    state: { referrer: 'schedule' },
                                  }}
                                >
                                  <TaskNameContainer
                                    text={cellData}
                                    width={(minWidth - WIDTHS) / 2}
                                    isKeyFinish={rowData.get('isKeyFinish')}
                                    isKeyStart={rowData.get('isKeyStart')}
                                    isCritical={isCritical}
                                  />
                                </Link>
                              </div>
                            </span>
                            {isExpanded && (
                              <SubTasksList subTasks={subTasks} rowData={rowData} controller={controller} />
                            )}
                          </>
                        );
                      })}
                      style={{ marginTop: '25px' }}
                    />
                    <Column
                      label="Account Assignee"
                      dataKey="assigneeAccount"
                      className="h-100 mt-4 pt-3"
                      width={(minWidth - WIDTHS) / 2}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const companyId = cellData.get('companyId');
                        const isAssigned = !!companyId;
                        const isAssigneeAccountEditing =
                          rowIndex === editingRow && defaultEditField === 'assigneeAccount';

                        if (isAssigneeAccountEditing) {
                          const company = companies.find(c => c.get('subAccountId') === companyId);
                          const selected = company ? [company] : [];

                          return (
                            <Typeahead
                              id="account-assignee"
                              defaultValue={cellData.get('company')}
                              placeholder="Needs Assignment"
                              options={companies}
                              labelKey={option => option.get('name')}
                              selected={selected}
                              flip={true}
                              defaultOpen={defaultEditField === 'assigneeAccount'}
                              isMyId={option => account.get('_id') === option.get('subAccountId')}
                              filterSelected={(option, selected) =>
                                selected.get('subAccountId') !== option.get('subAccountId')
                              }
                              onChange={([company]) =>
                                controller.assignTask({
                                  task: rowData,
                                  subId: company ? company.get('subAccountId') : null,
                                })
                              }
                            />
                          );
                        }
                        return (
                          <AssigneeBadge
                            onClick={onEditFieldClick(rowIndex, 'assigneeAccount')}
                            maxWidth={(minWidth - WIDTHS) / 2}
                            className={`text-truncate ${getAssigneeBadgeClassName(
                              cellData.get('companyId'),
                              account.get('_id')
                            )}`}
                          >
                            {isAssigned ? cellData.get('company') : 'Needs Assignment'}
                            <FontAwesomeIcon icon="chevron-down" className="ml-1" />
                          </AssigneeBadge>
                        );
                      })}
                    />
                    <Column
                      label="Confirmed"
                      dataKey="startDateConfirmed"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.confirmed}
                      cellRenderer={renderCell(({ cellData }) => (
                        <div
                          className={cn('text-right mr-5 pr-2', {
                            'text-success': !!cellData,
                            'text-gray-200': !cellData,
                          })}
                        >
                          <FontAwesomeIcon icon="check" />
                        </div>
                      ))}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.expStart}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const { isOverdueStart } = isTaskOverdue(rowData);
                        const startDate = cellData ? datepickerFormat(mongoToTrx(cellData)) : '';
                        const textStartDate = startDate ? isoToTrxFull(cellData) : 'MM/DD/YY';
                        const lateStartDate = rowData.get('lateStartDate') || undefined;
                        const criticalStartDate = new Date(lateStartDate);

                        return (
                          <DatepickerWithPortal
                            name="startDate"
                            date={startDate}
                            isDefaultOpen={defaultEditField === 'startDate'}
                            onChange={date => controller.updateTaskStartDate({ date, task: rowData })}
                            onChangeRaw={({ target }) => controller.updateRawStartDate({ date: target.value, rowData })}
                            isOverdue={isOverdueStart}
                            dateText={textStartDate}
                            isEditing={rowIndex === editingRow && canEditDatesAndPredecessors}
                            canEdit={canEditDatesAndPredecessors}
                            portalRoot={calendarRef}
                            onClick={onEditFieldClick(rowIndex, 'startDate')}
                            dayClassName={date => criticalClass(date, criticalStartDate, startDate)}
                            renderDayContents={(day, date) => CriticalDay(day, date, criticalStartDate, startDate)}
                            calendarClassName="react-datepicker__critical"
                          >
                            <WarningCriticalMsg showMsg={showWarningCriticalMsg(startDate, criticalStartDate)} />
                          </DatepickerWithPortal>
                        );
                      })}
                    />
                    <Column
                      label="Exp. Finish"
                      dataKey="expectedFinishDate"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.expFinish}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const minEndDate = getMinEndDate(rowData.get('startDate'));
                        const { isOverdueFinish } = isTaskOverdue(rowData);
                        const endDate = cellData ? datepickerFormat(mongoToTrx(cellData)) : '';
                        const textEndDate = endDate ? isoToTrxFull(endDate) : 'MM/DD/YY';
                        const lateEndDate = rowData.get('lateEndDate') || undefined;
                        const criticalEndDate = new Date(lateEndDate);
                        return (
                          <DatepickerWithPortal
                            name="endDate"
                            date={endDate}
                            isDefaultOpen={defaultEditField === 'endDate'}
                            minDate={minEndDate}
                            onChange={date => controller.updateEndDateAndDuration({ date, task: rowData })}
                            onChangeRaw={event => controller.updateRawEndDate({ date: event.target.value, rowData })}
                            isOverdue={isOverdueFinish}
                            dateText={textEndDate}
                            isEditing={rowIndex === editingRow && canEditDatesAndPredecessors}
                            canEdit={canEditDatesAndPredecessors}
                            portalRoot={calendarRef}
                            onClick={onEditFieldClick(rowIndex, 'endDate')}
                            dayClassName={date => criticalClass(date, criticalEndDate, endDate)}
                            renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, endDate)}
                            calendarClassName="react-datepicker__critical"
                          >
                            <WarningCriticalMsg showMsg={showWarningCriticalMsg(endDate, criticalEndDate)} />
                          </DatepickerWithPortal>
                        );
                      })}
                    />
                    <Column
                      label="Duration"
                      dataKey="durationDays"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.duration}
                      cellRenderer={renderCell(({ rowData, cellData, rowIndex }) => {
                        if (rowIndex === editingRow && canEditDatesAndPredecessors) {
                          return <TaskDurationInput controller={controller} task={rowData} />;
                        }

                        return (
                          <div
                            onClick={onEditFieldClick(rowIndex, 'durationDays')}
                            className={cn({ editable: canEditDatesAndPredecessors })}
                          >
                            {cellData}d
                          </div>
                        );
                      })}
                    />
                    <Column
                      label="Dep."
                      dataKey="predecessors"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.dep}
                      cellRenderer={renderCell(({ rowData, cellData }) => {
                        const predecessorsString = cellData
                          .map(p => {
                            const taskAux = tasks.find(t => t.get('id') === p.get('taskId'));
                            const depType = taskAux && p.get('dependencyType') !== 'FS' ? p.get('dependencyType') : '';
                            return taskAux ? depType + taskAux.get('rowIndex') : '';
                          })
                          .toJS()
                          .join();
                        return (
                          <>
                            <Tooltip text={predecessorsString}>
                              <span
                                className={cn({ editable: canEditDatesAndPredecessors })}
                                onClick={() => canEditDatesAndPredecessors && changeDependencies(rowData)}
                              >
                                {predecessorsString && (
                                  <div className="d-flex align-items-center">
                                    <span className="d-inline-block text-truncate mr-1" style={{ maxWidth: '40px' }}>
                                      {predecessorsString}
                                    </span>
                                    {canEditDatesAndPredecessors && <FontAwesomeIcon icon="pen" />}
                                  </div>
                                )}
                                {!predecessorsString && canEditDatesAndPredecessors && (
                                  <FontAwesomeIcon icon="pen-to-square" />
                                )}
                              </span>
                            </Tooltip>
                            {rowData.get('missingReference') && (
                              <div className="text-danger" style={{ marginLeft: '-10px' }}>
                                Missing
                              </div>
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      className="h-100 mt-2 pt-3"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ rowData }) => {
                        const taskStatus = !!rowData.get('checkedIn') ? 'checked-in' : rowData.get('status');
                        const isExpanded = !!expandedRows.get(String(rowData.get('id')));
                        return (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <ChipStatusDropdown
                                {...STATUS_MAP[taskStatus]}
                                notStarted={taskStatus !== NOT_STARTED}
                                progress={taskStatus !== IN_PROGRESS}
                                completed={taskStatus !== COMPLETED}
                                onSelect={status => controller.updateStatus({ task: rowData, status })}
                                data-testid="dropdown-task-status"
                              />
                              {showDaysBehind && (
                                <span className="position-absolute mt-4 pt-2">
                                  <OnTime onTime={rowData.get('daysBehind')} hideOnSchedule />
                                </span>
                              )}
                            </div>
                            {isExpanded && (
                              <SubTaskStatusList
                                task={rowData}
                                subTasks={rowData.get('children')}
                                controller={controller}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label=""
                      dataKey=""
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.action}
                      cellRenderer={renderCell(({ rowData }) => (
                        <TableRowDropDown remove onSelect={() => controller.deleteTask({ task: rowData })} />
                      ))}
                    />
                  </Table>
                );
              }}
            </AutoSizer>
          </ScrollDiv>
        )}
      </InfiniteLoader>
    </>
  );
};

const ScrollDiv = styled.div`
  flex: 1 1 auto;
  ${scrollbarStyles}
`;
