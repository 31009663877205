import { useEffect, useRef } from 'react';
import { Map, List, fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import { useFiltersContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';
import * as actions from './OuttakeActions';

export const OUTTAKE_FILTER = 'outtakeFilter';
export const MINIMUM_BATCH_SIZE = 25;
export const ALL_TASKS = 'all-tasks';
export const TASK_TYPE = 'task-type';

export const RULE_MAP = {
  account: 'assigneeAccountName',
  super: 'userSuper',
  scheduler: 'userScheduler',
};

const emptySelectedItems = fromJS({
  selectAll: false,
  notIncludeTaskIds: [],
  taskIds: [],
  taskNames: [],
  currentAssignees: [],
});

const emptyState = fromJS({
  community: Map({
    _id: 'aaaaaabbbbbbccccccdddddd',
    name: '',
  }),
  allTasks: Map({
    tasks: List(),
    totalCount: 10,
    maxCount: 10,
  }),
  tasksBy: ALL_TASKS,
  tasksByType: Map({
    tasks: List(),
    totalCount: 10,
    maxCount: 10,
  }),
  tab: 'account',
  selectedTasks: {
    account: emptySelectedItems,
    super: emptySelectedItems,
    scheduler: emptySelectedItems,
  },
});

export const OuttakeController = communityId => {
  const loaderRef = useRef();
  const appContext = useAppContext();

  const filtersContext = useFiltersContext();
  const { filtersState } = filtersContext;
  const [state, controller] = useController(actions, emptyState, { ...appContext, loaderRef, filtersState, getFilter });
  const tab = state.get('tab');
  const filterName = getFilterName(tab);
  const filter = filtersState.getIn([filterName, 'values']);
  const isFiltering = filtersState.getIn([filterName, 'isFiltering']);

  useEffect(() => {
    controller.readCommunity(communityId);
  }, [communityId, controller]);

  useEffect(() => {
    controller.invalidateFilter();
  }, [controller, filter]);

  return { state, controller, loaderRef, filterName, isFiltering };
};

function getFilterName(tab) {
  return tab === 'account' ? FILTER_NAME.OUTTAKE_BY_ACCOUNT : FILTER_NAME.OUTTAKE_BY_USER;
}

function getFilter(tab) {
  const filterName = getFilterName(tab);
  const filter = this.filtersState.getIn([filterName, 'values']);
  return filter;
}
