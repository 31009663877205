import React from 'react';
import { Modal } from 'react-bootstrap';
import cn from 'classnames';
import { PrimaryButton } from '@tradetrax/web-common';
import { Impact } from '@tradetrax/web-common/lib/Task/Impact';
import { plural } from '@tradetrax/web-common/lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ConfirmationText({ isStartDate, hasPredecessors, isJobDelayed }) {
  return (
    <>
      {isStartDate && hasPredecessors && !isJobDelayed && (
        <p className="px-4 pt-3 font-size-14 text-gray-400">
          Manually changing the Exp. Start will also result in{' '}
          <strong>all Predecessor relationships being removed.</strong> Please double-check before confirming.
        </p>
      )}
      {isJobDelayed && (!hasPredecessors || !isStartDate) && (
        <p className="px-4 pt-3 font-size-14 text-gray-400">
          By confirming this update will <strong>delay the finish date for the Job</strong>. Please double-check before
          confirming.
        </p>
      )}
      {isJobDelayed && hasPredecessors && isStartDate && (
        <p className="px-4 pt-3 font-size-14 text-gray-400">
          By confirming this update will result in <strong>all Predecessor relationships being removed</strong>. It will
          also <strong>delay the finish date for the Job</strong>. Please double-check before confirming.
        </p>
      )}
    </>
  );
}

export function ScheduleImpactModal({ state, controller, ...rest }) {
  const { isStartDate = false, accept, close } = rest;
  const { task, tasksAffected, jobDelay, tasksAffectedSize } = state.toObject();
  const [isChecked, setIsChecked] = React.useState(false);
  const numberTasksHeld = tasksAffected.reduce((acc, task) => (task.get('isHeld') ? ++acc : acc), 0);
  const hasPredecessors = !!task.get('predecessors').size;
  const assignee = task.getIn(['assigneeAccount', 'company']);
  const isJobDelayed = jobDelay > 0;
  const impactText = jobDelay === 0 ? 'Cycle Time Impact' : isJobDelayed ? 'Increase Cycle Time' : 'Reduce Cycle Time';
  const isClickable = tasksAffectedSize || numberTasksHeld;

  const onConfirmDateChange = () => {
    const tasksHeld = tasksAffected
      .filter(task => task.get('isHeld') && task.get('isDirectSuccesor'))
      .map(task => task.get('id'));
    accept({ tasksHeld, isDueWeather: isChecked });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Schedule Impact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 mb-n3">
        <div className="ml-3 font-weight-bold font-size-16">{`Task: ${task.get('name')}`}</div>
        <span className="small ml-3">
          {assignee ? (
            <>
              Assigned to <span className="text-primary">{assignee}</span>
            </>
          ) : (
            <>Task has not been assigned</>
          )}
        </span>
        <Impact className="mx-3">
          <li className={cn({ 'd-none': !numberTasksHeld })}>
            <span>Tasks Held</span>
            <span className="text-gray-400 pr-2">{numberTasksHeld}</span>
          </li>
          <li
            onClick={() => isClickable && controller.showTasksAffectedView()}
            className={cn({
              clickable: isClickable,
              'text-danger': tasksAffectedSize,
            })}
          >
            <label>Tasks Affected</label>
            <span className={cn('d-flex align-items-center', { 'text-gray-400': !tasksAffectedSize })}>
              {tasksAffectedSize}{' '}
              <FontAwesomeIcon
                icon="chevron-right"
                className={cn('text-dark font-size-12 ml-1', { 'd-none': !isClickable })}
              />
            </span>
          </li>
          <li
            className={cn({
              'text-danger': isJobDelayed,
              'text-success': !isJobDelayed && jobDelay !== 0,
            })}
          >
            <label>{impactText}</label>
            <span className="pr-2">{plural.day(Math.abs(jobDelay))}</span>
          </li>
          <li>
            <span className="font-weight-bold">Due to Weather</span>
            <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          </li>
        </Impact>
        <ConfirmationText isStartDate={isStartDate} hasPredecessors={hasPredecessors} isJobDelayed={isJobDelayed} />
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row">
        <PrimaryButton variant="secondary" onClick={() => close()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton className="mr-0 ml-3" onClick={onConfirmDateChange}>
          Confirm Date Change
        </PrimaryButton>
      </Modal.Footer>
    </>
  );
}

export function StageScheduleImpactModal({ jobDelay, hasPredecessors, isStartDate, stage, accept, close }) {
  const isJobDelayed = jobDelay > 0;
  const impactText = jobDelay === 0 ? 'Cycle Time Impact' : isJobDelayed ? 'Increase Cycle Time' : 'Reduce Cycle Time';

  return (
    <Modal show={true} onHide={() => close()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Schedule Impact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 mb-n3">
        <div className="ml-3 font-weight-bold font-size-16">{`Stage: ${stage.get('name')}`}</div>
        <Impact className="mx-3">
          <li
            className={cn({
              'text-danger': isJobDelayed,
              'text-success': !isJobDelayed && jobDelay !== 0,
            })}
          >
            <label>{impactText}</label>
            <span>{plural.day(Math.abs(jobDelay))}</span>
          </li>
        </Impact>

        <ConfirmationText isStartDate={isStartDate} hasPredecessors={hasPredecessors} isJobDelayed={isJobDelayed} />
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row">
        <PrimaryButton variant="secondary" onClick={() => close()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton className="mr-0 ml-3" onClick={() => accept()}>
          Confirm Date Change
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}
